import { forwardRef } from 'react';

//* Provider
import { LanguageConsumer } from '../providers/LanguageProvider';

//* Helpers
import { getContext } from '@/helpers';

export default function withLanguageContext(ConnectedComponent, properties = []) {
	return forwardRef((props, ref) => {
		return (
			<LanguageConsumer>
				{(context) => {
					let contextProps = properties.length ? getContext(context, properties) : {};

					return (
						<ConnectedComponent
							ref={ref}
							{...props}
							{...contextProps}
						/>
					);
				}}
			</LanguageConsumer>
		);
	});
}
