export default {
	colors: {
		//! Primary Colors
		blue1000Color: '#00375F',
		blue900Color: '#1A4B6F',
		blue800Color: '#335F7F',
		blue700Color: '#4D738F',
		blue600Color: '#66879F',
		blue500Color: '#809BAF',
		blue400Color: '#99AFBF',
		blue300Color: '#B3C3CF',
		blue200Color: '#CCD7DF',
		blue100Color: '#E6EBEF',
		blue50Color: '#F2F5F7',

		orange1200Color: '#FF9A00',
		orange1000Color: '#FFB441',
		orange800Color: '#FFC367',
		orange600Color: '#39414B',
		orange400Color: '#51575F',
		orange200Color: '#676C72',
		orange100Color: '#7D8186',
		orange50Color: '#939699',

		//! Secondary Colors
		errorColor: '#FF6E73',
		successColor: '#78A091',
		brandCreamColor: '#C8AFAF',
		brandLightBlueColor: '#6EC8F0',
		whiteColor: '#FFF',

		//! Inverse Colors
		inverseBlueDarkColor: '#004476',
		inverseBlueColor: '#004D86',
		inverseBlueLightColor: '#005EA1',

		//! Background Color
		backgroundColor: '#FFF',
		surfaceColor: '#F7F7F7',

		//! Neutral Colors
		neutral1000Color: '#000',
		neutral900Color: '#171717',
		neutral800Color: '#2E2E2F',
		neutral700Color: '#444546',
		neutral600Color: '#5B5C5E',
		neutral500Color: '#727475',
		neutral400Color: '#898B8C',
		neutral300Color: '#A0A2A4',
		neutral200Color: '#B5B9BB',
		neutral150Color: '#CDD0D3',
		neutral100Color: '#E1E3E5',
		neutral75Color: '#E4E7EA',
		neutral50Color: '#E7E9EC',
		neutral40Color: '#E9ECEE',
		neutral35Color: '#ECEEF0',
		neutral30Color: '#EFF1F2',
		neutral25Color: '#F2F3F5',
		neutral20Color: '#F4F5F7',
		neutral15Color: '#F7F8F9',
		neutral10Color: '#FAFAFB',
		neutral5Color: '#FCFDFD',
	},

	mediaQuery: {
		desktopSizeXL: '2560px',
		desktopSizeL: '1920px',
		desktopSizeM: '1536px',
		desktopSizeS: '1366px',
		tabletSize: '1280px',
		tabletSizeX: '1024px',
		tabletSizeS: '768px',

		desktopSizeXLMin: '2559px',
		desktopSizeLMin: '1919px',
		desktopSizeMMin: '1535px',
		desktopSizeSMin: '1365px',
		tabletSizeMin: '1279px',
		tabletSizeXMin: '1023px',
		tabletSizeSMin: '767px',
	},
};
