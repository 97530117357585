export default {
	routes: {
		home: {
			path: '/',
			name: 'home',
			headerVariant: {
				transparent: true,
				distance: false,
			},
		},

		feedback: {
			path: '/feedback',
			name: 'feedback',
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		theTrail: {
			path: '/the-trail/',
			name: 'theTrail',
			header: 1,
			footer: 1,
			headerVariant: {
				transparent: true,
				distance: false,
			},
		},

		about: {
			path: '/about/',
			name: 'about',
			header: 5,
			footer: 2,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		regions: {
			path: '/regions/',
			name: 'regions',
			header: 2,
			arrow: true,
			headerVariant: {
				transparent: false,
				distance: true,
			},
			children: {
				headerVariant: {
					transparent: true,
					distance: false,
				},
			},
		},

		usefulInfo: {
			path: '/useful-info/',
			name: 'usefulInfo',
			header: 3,
			arrow: true,
			headerVariant: {
				transparent: false,
				distance: true,
			},
			children: {
				headerVariant: {
					transparent: false,
					distance: true,
				},
			},
		},

		news: {
			path: '/news/',
			name: 'newsAndStories',
			footer: 3,
			header: 4,
			headerVariant: {
				transparent: false,
				distance: true,
			},
			children: {
				headerVariant: {
					transparent: true,
					distance: false,
				},
			},
		},

		stages: {
			path: '/stages/',
			name: 'stages',
			headerVariant: {
				transparent: false,
				distance: true,
			},
			children: {
				headerVariant: {
					transparent: true,
					distance: false,
				},
			},
		},

		contact: {
			path: '/contact-us/',
			name: 'contacts',
			footer: 4,
			header: 6,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		interactiveMap: {
			path: '/interactive-map/',
			name: 'interactiveMap',
			footer: 6,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		termsOfService: {
			path: '/terms-of-service/',
			name: 'termsOfService',
			info: 1,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		dataDeletion: {
			path: '/data-deletion/',
			name: 'dataDeletion',
			info: 4,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		cookiePolicy: {
			path: '/cookie-policy/',
			name: 'cookiePolicy',
			info: 3,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		disclaimer: {
			path: '/disclaimer/',
			name: 'disclaimer',
			info: 2,
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},

		search: {
			path: '/search/',
			name: 'search',
			headerVariant: {
				transparent: false,
				distance: true,
			},
		},
	},
};
