import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';

	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% }`;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	.overflow-hidden {
		overflow: hidden;
	}

	.opacity {
		&-0 {
			opacity: 0;
		}

		&-9 {
			opacity: 0.9;
		}
		&-1 {
			opacity: 1;
		}
	}

	.translateY {
		&-0 {
			transform: translateY(0);
		}

		&-100 {
			transform: translateY(100vh);
		}
	}

	//! Swiper Block Navigation
	.swiper-pagination-block {
		display: flex;
		align-items: center;
		gap: var(--distanceIndex);

		.wrapper-number-navigation {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			justify-items: center;
			gap: var(--sp0-5x);
		}

		i {
			font-size: var(--h6);
			color: var(--orange1000Color);
			line-height: calc(var(--h2) * var(--lineHeightM));
			transition: transform var(--trTime) ease-out;
			pointer-events: none;
			cursor: pointer;

			&:before {
				pointer-events: all;
			}

			&.swiper-button-disabled {
				opacity: 0.5;
				pointer-events: none;

				&:before {
					pointer-events: none;
				}
			}
		}
		&.item-bottom-wrapper {
			i {
				line-height: 1;
			}
		}
	}

	//! Fonts
	.font-montserrat-thin {
		font-family: var(--montserrat_thin);
		font-weight: 300;
	}

	.font-montserrat-regular {
		font-family: var(--montserrat_regular);
		font-weight: 400;
	}

	.font-montserrat-medium {
		font-family: var(--montserrat_medium);
		font-weight: 500;
	}

	.font-montserrat-semibold {
		font-family: var(--montserrat_semibold);
		font-weight: 600;
	}

	.font-active-grotesk-normal {
		font-family: 'aktiv-grotesk-armenian';
		font-weight: 400;
	}

	.font-active-grotesk-medium {
		font-family: 'aktiv-grotesk-armenian';
		font-weight: 500;
	}

	.font-active-grotesk-semibold {
		font-family: 'aktiv-grotesk-armenian';
		font-weight: 600;
	}

	.font-active-grotesk-bold {
		font-family: 'aktiv-grotesk-armenian';
		font-weight: 700;
	}

	//! Primary Colors
	.blue-1000-color {
		color: var(--blue1000Color);

		&-bg {
			background-color: var(--blue1000Color);
		}
	}

	.blue-900-color {
		color: var(--blue900Color);

		&-bg {
			background-color: var(--blue900Color);
		}
	}

	.blue-800-color {
		color: var(--blue800Color);

		&-bg {
			background-color: var(--blue800Color);
		}
	}

	.blue-700-color {
		color: var(--blue700Color);

		&-bg {
			background-color: var(--blue700Color);
		}
	}

	.blue-600-color {
		color: var(--blue600Color);

		&-bg {
			background-color: var(--blue600Color);
		}
	}

	.blue-500-color {
		color: var(--blue500Color);

		&-bg {
			background-color: var(--blue500Color);
		}
	}

	.blue-400-color {
		color: var(--blue400Color);

		&-bg {
			background-color: var(--blue400Color);
		}
	}

	.blue-300-color {
		color: var(--blue300Color);

		&-bg {
			background-color: var(--blue300Color);
		}
	}

	.blue-200-color {
		color: var(--blue200Color);

		&-bg {
			background-color: var(--blue200Color);
		}
	}

	.blue-100-color {
		color: var(--blue100Color);

		&-bg {
			background-color: var(--blue100Color);
		}
	}

	.blue-50-color {
		color: var(--blue50Color);

		&-bg {
			background-color: var(--blue50Color);
		}
	}

	.orange-1200-color {
		color: var(--orange1200Color);

		&-bg {
			background-color: var(--orange1200Color);
		}
	}

	.orange-1000-color {
		color: var(--orange1000Color);

		&-bg {
			background-color: var(--orange1000Color);
		}
	}

	.orange-800-color {
		color: var(--orange800Color);

		&-bg {
			background-color: var(--orange800Color);
		}
	}

	.orange-600-color {
		color: var(--orange600Color);

		&-bg {
			background-color: var(--orange600Color);
		}
	}

	.orange-400-color {
		color: var(--orange400Color);

		&-bg {
			background-color: var(--orange400Color);
		}
	}

	.orange-200-color {
		color: var(--orange200Color);

		&-bg {
			background-color: var(--orange200Color);
		}
	}

	.orange-100-color {
		color: var(--orange100Color);

		&-bg {
			background-color: var(--orange100Color);
		}
	}

	.orange-50-color {
		color: var(--orange50Color);

		&-bg {
			background-color: var(--orange50Color);
		}
	}

	//! Secondary Colors
	.error-color {
		color: var(--errorColor);

		&-bg {
			background-color: var(--errorColor);
		}
	}

	.success-color {
		color: var(--successColor);

		&-bg {
			background-color: var(--successColor);
		}
	}

	.brand-cream-color {
		color: var(--brandCreamColor);

		&-bg {
			background-color: var(--brandCreamColor);
		}
	}

	.brand-light-blue-color {
		color: var(--brandLightBlueColor);

		&-bg {
			background-color: var(--brandLightBlueColor);
		}
	}

	.white-color {
		color: var(--whiteColor);

		&-bg {
			background-color: var(--whiteColor);
		}
	}

	.surface-color {
		color: var(--surfaceColor);

		&-bg {
			background-color: var(--surfaceColor);
		}
	}

	//! Inverse Colors
	.inverse-blue-dark-color {
		color: var(--inverseBlueDarkColor);

		&-bg {
			background-color: var(--inverseBlueDarkColor);
		}
	}

	.inverse-blue-color {
		color: var(--inverseBlueColor);

		&-bg {
			background-color: var(--inverseBlueColor);
		}
	}

	.inverse-blue-light-color {
		color: var(--inverseBlueLightColor);

		&-bg {
			background-color: var(--inverseBlueLightColor);
		}
	}

	//! Neutral Colors
	.neutral-1000-color {
		color: var(--neutral1000Color);

		&-bg {
			background-color: var(--neutral1000Color);
		}
	}

	.neutral-900-color {
		color: var(--neutral900Color);

		&-bg {
			background-color: var(--neutral900Color);
		}
	}

	.neutral-800-color {
		color: var(--neutral800Color);

		&-bg {
			background-color: var(--neutral800Color);
		}
	}

	.neutral-700-color {
		color: var(--neutral700Color);

		&-bg {
			background-color: var(--neutral700Color);
		}
	}

	.neutral-600-color {
		color: var(--neutral600Color);

		&-bg {
			background-color: var(--neutral600Color);
		}
	}

	.neutral-500-color {
		color: var(--neutral500Color);

		&-bg {
			background-color: var(--neutral500Color);
		}
	}

	.neutral-400-color {
		color: var(--neutral400Color);

		&-bg {
			background-color: var(--neutral400Color);
		}
	}

	.neutral-300-color {
		color: var(--neutral300Color);

		&-bg {
			background-color: var(--neutral300Color);
		}
	}

	.neutral-200-color {
		color: var(--neutral200Color);

		&-bg {
			background-color: var(--neutral200Color);
		}
	}

	.neutral-100-color {
		color: var(--neutral100Color);

		&-bg {
			background-color: var(--neutral100Color);
		}
	}

	.neutral-75-color {
		color: var(--neutral75Color);

		&-bg {
			background-color: var(--neutral75Color);
		}
	}

	.neutral-50-color {
		color: var(--neutral50Color);

		&-bg {
			background-color: var(--neutral50Color);
		}
	}

	.neutral-40-color {
		color: var(--neutral40Color);

		&-bg {
			background-color: var(--neutral40Color);
		}
	}

	.neutral-35-color {
		color: var(--neutral35Color);

		&-bg {
			background-color: var(--neutral35Color);
		}
	}

	.neutral-30-color {
		color: var(--neutral30Color);

		&-bg {
			background-color: var(--neutral30Color);
		}
	}

	.neutral-25-color {
		color: var(--neutral25Color);

		&-bg {
			background-color: var(--neutral25Color);
		}
	}

	.neutral-20-color {
		color: var(--neutral20Color);

		&-bg {
			background-color: var(--neutral20Color);
		}
	}

	.neutral-15-color {
		color: var(--neutral15Color);

		&-bg {
			background-color: var(--neutral15Color);
		}
	}

	.neutral-10-color {
		color: var(--neutral10Color);

		&-bg {
			background-color: var(--neutral10Color);
		}
	}

	.neutral-5-color {
		color: var(--neutral5Color);

		&-bg {
			background-color: var(--neutral5Color);
		}
	}

	//! For Text
	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline;
	}

	.text-center {
		text-align: center;
	}

	.text-end {
		text-align: end;
	}

	.white-pre-wrap {
		white-space: pre-wrap;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	//! Grid
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		${columnGenerator()};

		//! 1920
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeXLMin}) {
			${columnGenerator('dxl')}
		}

		//! 1536
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeLMin}) {
			${columnGenerator('dl')}
		}

		//! 1366
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeMMin}) {
			${columnGenerator('dm')}
		}

		//! 1280
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeSMin}) {
			${columnGenerator('ds')}
		}

		//! 1024
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeMin}) {
			${columnGenerator('t')}
		}

		//! 768
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeXMin}) {
			${columnGenerator('dx')}
		}

		//! Mobile
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}
`}`;

export default HelperClass;
