import { createGlobalStyle, css } from 'styled-components';
import localFont from 'next/font/local';
import theme from './theme';

//! Space Generator
function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

//! Color Generator
function colorGenerator() {
	const colorsArr = Object.keys(theme.colors);

	let colorVariable = '';

	for (let i = 0; i < colorsArr.length; i++) {
		colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`;
	}

	return colorVariable;
}

//! Fonts
const montserrat_thin = localFont({
	src: [
		{
			path: 'fonts/Montserratarm-Thin.woff',
			weight: '300',
			display: 'swap',
			style: 'normal',
		},
	],
});

const montserrat_regular = localFont({
	src: [
		{
			path: 'fonts/Montserratarm-Regular.woff',
			weight: '400',
			display: 'swap',
			style: 'regular',
		},
	],
});

const montserrat_medium = localFont({
	src: [
		{
			path: 'fonts/Montserratarm-Medium.woff',
			weight: '500',
			display: 'swap',
			style: 'medium',
		},
	],
});

const montserrat_semibold = localFont({
	src: [
		{
			path: 'fonts/Montserratarm-SemiBold.woff',
			weight: '600',
			display: 'swap',
			style: 'semibold',
		},
	],
});

const Variables = createGlobalStyle`${css`
	:root {
		//! Fonts
		--montserrat_thin: ${montserrat_thin.style.fontFamily};
		--montserrat_regular: ${montserrat_regular.style.fontFamily};
		--montserrat_medium: ${montserrat_medium.style.fontFamily};
		--montserrat_semibold: ${montserrat_semibold.style.fontFamily};

		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		${spaceGenerator()};

		//! Transition
		--trTime: 0.3s;

		//! Line Heights
		--lineHeightM: 1.3;
		--lineHeightL: 1.5;

		//! Colors
		${colorGenerator()};

		//! Screen Sizes
		--desktopSizeXL: ${(props) => props.theme.mediaQuery.desktopSizeXL};
		--desktopSizeL: ${(props) => props.theme.mediaQuery.desktopSizeL};
		--desktopSizeM: ${(props) => props.theme.mediaQuery.desktopSizeM};
		--desktopSizeS: ${(props) => props.theme.mediaQuery.desktopSizeS};
		--tabletSize: ${(props) => props.theme.mediaQuery.tabletSize};
		--tabletSizeS: ${(props) => props.theme.mediaQuery.tabletSizeS};

		//! Grid Column Distance
		--colPadding: calc(var(--gutter) / 2);
	}

	/* //! 2560 */
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXL}) {
		:root {
			--h1: 80px;
			--h2: 72px;
			--h3: 56px;
			--h4: 48px;
			--h5: 40px;
			--h6: 32px;
			--p1: 28px;
			--p2: 24px;
			--p3: 20px;
			--p4: 18px;
			--p5: 16px;
			--p6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp17x);
			--gutter: var(--sp4x);
			--sectionDistance: var(--sp30x);
			--headerFixedHeight: var(--sp17x);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		:root {
			--h1: 64px;
			--h2: 56px;
			--h3: 48px;
			--h4: 40px;
			--h5: 32px;
			--h6: 24px;
			--p1: 24px;
			--p2: 20px;
			--p3: 18px;
			--p4: 16px;
			--p5: 14px;
			--p6: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp12x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp20x);
			--headerFixedHeight: var(--sp14x);
		}
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		:root {
			--h1: 56px;
			--h2: 48px;
			--h3: 40px;
			--h4: 32px;
			--h5: 28px;
			--h6: 20px;
			--p1: 20px;
			--p2: 18px;
			--p3: 18px;
			--p4: 16px;
			--p5: 14px;
			--p6: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp10x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp18x);
			--headerFixedHeight: var(--sp10x);
		}
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		:root {
			--h1: 48px;
			--h2: 40px;
			--h3: 32px;
			--h4: 28px;
			--h5: 24px;
			--h6: 18px;
			--p1: 18px;
			--p2: 18px;
			--p3: 16px;
			--p4: 14px;
			--p5: 14px;
			--p6: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp8x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp17x);
			--headerFixedHeight: var(--sp10x);
		}
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		:root {
			--h1: 48px;
			--h2: 40px;
			--h3: 32px;
			--h4: 28px;
			--h5: 24px;
			--h6: 16px;
			--p1: 18px;
			--p2: 18px;
			--p3: 16px;
			--p4: 14px;
			--p5: 14px;
			--p6: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp6x);
			--gutter: var(--sp2-5x);
			--sectionDistance: var(--sp15x);
			--headerFixedHeight: var(--sp9x);
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		:root {
			--h1: 40px;
			--h2: 32px;
			--h3: 28px;
			--h4: 28px;
			--h5: 24px;
			--h6: 16px;
			--p1: 18px;
			--p2: 18px;
			--p3: 16px;
			--p4: 14px;
			--p5: 14px;
			--p6: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp4x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);
			--headerFixedHeight: var(--sp8x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		:root {
			--h1: 32px;
			--h2: 28px;
			--h3: 28px;
			--h4: 24px;
			--h5: 20px;
			--h6: 14px;
			--p1: 16px;
			--p2: 16px;
			--p3: 16px;
			--p4: 14px;
			--p5: 14px;
			--p6: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp2x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp10x);
			--headerFixedHeight: var(--sp8x);
		}
	}
`}`;

export default Variables;
