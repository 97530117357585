import { forwardRef } from 'react';

//* Provider
import { UIConsumer } from '../providers/UIProvider';

//* Helpers
import { getContext } from '@/helpers';

export default function withUIContext(ConnectedComponent, properties = []) {
	return forwardRef((props, ref) => {
		return (
			<UIConsumer>
				{(context) => {
					let contextProps = properties.length ? getContext(context, properties) : {};

					return (
						<ConnectedComponent
							ref={ref}
							{...props}
							{...contextProps}
						/>
					);
				}}
			</UIConsumer>
		);
	});
}
