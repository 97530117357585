'use client';

import { useState, createContext, useMemo } from 'react';

//* HOC's
import withLanguageContext from '../consumerHOC/LanguageConsumer';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
	const [state, setState] = useState({
		...props.newData,
		prevRoute: null,
	});

	const enableLoading = () => {
		this.setState({ loading: true });
	};

	const disableLoading = () => {
		setState((prev) => ({ ...prev, loading: false }));
	};

	const contactUs = async (data) => {
		return await api.post('contact-us', data);
	};

	const subscribe = async (data) => {
		return await api.post('subscribe', data);
	};

	const feedback = async (data) => {
		return await api.post('testimonial', data);
	};

	const globalData = useMemo(() => state.globalData?.[props.selectedLang], [props.selectedLang, state]);

	const methods = {
		enableLoading,
		disableLoading,
		contactUs,
		subscribe,
		feedback,
	};

	return <DataContext.Provider value={{ ...state, ...methods, globalData }}>{props.children}</DataContext.Provider>;
};

export default withLanguageContext(DataProvider, ['selectedLang']);
